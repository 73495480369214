import { server, domains, locales } from '../config/index';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { useState, useEffect } from 'react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next';

export default function Custom404({ categories }){
    const [size, setSize] = useState(0);
    const { t } = useTranslation('common');
    
    useEffect(() => {
        function updateSize() {
            setSize(window.innerHeight - (document.querySelector(".humoqFixedMenu").clientHeight) - (document.querySelector(".mainFooter").clientHeight) - 110);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    
    return(
        <>
        <Header cat={categories} />
        <div className="humoqRow gameDetails">
            <div className="containerNew" style={{minHeight: size}}>
                <div className='Scope404'>
                    <div className='Title404'>Oops...</div>
                    <div className='SubDesc404'>Sorry, the page not found</div>
                    <img src='/page404.png'></img>
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}

export const getStaticProps = async (context) => {
    const domainId = locales[context.defaultLocale];
    const category_url = `${server}/api/v1/categories?domain_id=${domainId}`;
    const res_category = await fetch(category_url);
    const resultCatJSON = await res_category.json();
    const categories = resultCatJSON.data;
    
    return {
        props: {
            categories,
            ...(await serverSideTranslations(context.defaultLocale, ['common']))
        }
    }
}